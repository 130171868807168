<template>
  <v-row dense>
    <v-col
      sm="12"
      lg="6"
    >
      <material-invoices-and-quotations-quotations-card :client-id="clientId" />
      <v-divider class="d-lg-none" />
    </v-col>
    <v-col
      sm="12"
      lg="6"
    >
      <material-invoices-and-quotations-invoices-card :client-id="clientId" />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    clientId: {
      type: [String, Number],
      required: true
    }
  },
  created () {
    this.getClientData(this.clientId)
    this.getInvoicesAndQuotationsIfNeed(this.clientId)
  },
  methods: {
    ...mapActions('invoicesAndQuotations', [
      'getInvoicesAndQuotationsIfNeed'
    ]),
    ...mapActions('client', {
      getClientData: 'getDataByIdIfNeed'
    })
  }
}
</script>
